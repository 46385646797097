import React from 'react'
import {
  AlternativeHero,
  Breadcrumbs,
  Page,
  Products,
} from '@components'

const meta = {
  title: 'Bez okvira (Ciglice/PP čep) - Protupožarni moduli | Protupožarna zaštita',
  description: 'Protupožarni moduli bez okvira upotrebljavaju se za brtvljenje okruglih otvora gdje nema prašine. Pogodni su kod naknadnog provoda kabela u kasnijim fazama izgradnje. Mogu se koristiti u gipskartonskim i betonskim zidovima.',
}

const Category = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/proizvodi/', name: 'Proizvodi'},
        { link: `/proizvodi/protupozarni-moduli/`, name: 'Protupožarni moduli'},
        { link: `/proizvodi/protupozarni-moduli/s-okvirom/`, name: 'Bez okvira'},
      ]}
    />
    <AlternativeHero
      description="Protupožarni moduli bez okvira upotrebljavaju se za brtvljenje okruglih otvora gdje nema prašine. Pogodni su kod naknadnog provoda kabela u kasnijim fazama izgradnje. Mogu se koristiti u gipskartonskim i betonskim zidovima."
      slim
      title="Bez okvira (Ciglice/PP čep)"
      subtitle="Proizvodi - Protupožarni moduli"
    />
    <Products activeCategory="bez-okvira" />
  </Page>
)

export default Category